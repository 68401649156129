import React from 'react';
import Link from 'gatsby-link';
import { NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';


function MainMenu({ className, btnClassName }) {
  return (
    <>
      <NavItem>
        <NavLink
          className={className}
          tag={Link}
          to="/locations"
        >
          Locations
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={className}
          tag={Link}
          to="/menu"
        >
          Menu
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={className}
          tag={Link}
          to="/careers"
        >
          Careers
        </NavLink>
      </NavItem>
      {/* <NavItem>
        <NavLink
          className={className}
          tag={Link}
          to="/delivery"
        >
          Delivery
        </NavLink>
      </NavItem> */}
      <NavItem>
        <NavLink
          className={className}
          tag={Link}
          to="/offers"
        >
          Offers
        </NavLink>
      </NavItem>
      <NavLink
        className="btn btn-gradient-secondary mx-md-2"
        href="https://orders.subway-tt.com/takeaway/"
      >
        <strong
          className={classnames(btnClassName, 'text-primary', 'font-weight-bold')}
        >
          Order Now
        </strong>
      </NavLink>
    </>
  );
}

export default MainMenu;