import React from 'react';
import Helmet from 'react-helmet';

import Navigation from './Navigation';
import Footer from './Footer';
import '../styles/index.css';


function Layout({ title, meta, className, children }) {
  const defaultTitle = 'Subway Trinidad and Tobago';

  return (
    <>
      <Helmet
        title={title ? `${title} - ${defaultTitle}` : defaultTitle}
        meta={meta}
      />
      <Navigation />
      <main className={className ? `${className} flex-grow-1` : 'flex-grow-1'}>
        {children}
      </main>
      <Footer />
    </>
  );
}

Layout.defaultProps = {
  meta: [],
  className: null,
};

export default Layout;