import styled from 'styled-components';


const Logo = styled.img`
  width: 280px;

  @media (max-width: 768px) {
    width: 180px;
  }
`;

export default Logo;
