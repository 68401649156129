import React from 'react';
import Link from 'gatsby-link';
import { NavItem, NavLink } from 'reactstrap';
import Flag from "../assets/images/TnT.png";


function SecondaryMenu() {
  return (
    <>
      <NavItem>
        <NavLink
          tag={Link}
          to="/about"
          className="text-white px-2 pb-0"
        >
          About
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag={Link}
          to="/contact"
          className="text-white px-2 pb-0"
        >
          Contact
        </NavLink>
      </NavItem>
      <NavItem className="d-flex align-items-center">
        <img
          src={Flag}
          height={20}
          className="text-white px-2 pb-0 mt-2"
          alt=""
        />
      </NavItem>
    </>
  );
}

export default SecondaryMenu;