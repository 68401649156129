import React, { useState } from 'react';
import Link from 'gatsby-link';
import { Container, Navbar, NavbarBrand, NavbarToggler, Nav, Collapse } from 'reactstrap';

import logo from '../assets/images/Logo-Subway.png';
import MainMenu from './MainMenu';
import SecondaryMenu from './SecondaryMenu';
import SocialMedia from './SocialMedia';
import Logo from './Logo';


function Navigation() {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <>
      <Container fluid className="px-md-6 d-none d-md-block">
        <Nav className="justify-content-end">
          <SecondaryMenu />
        </Nav>
      </Container>
      <Navbar dark className="py-3 px-md-6 pt-md-0 pb-md-4" expand="md">
        <NavbarBrand tag={Link} to="/">
          <Logo src={logo} alt="SUBWAY" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className="border-0 px-0" />
        
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mx-auto" navbar>
            <MainMenu className="display-4" btnClassName="display-4" />
          </Nav>
          
          <Nav navbar className="flex-row justify-content-center">
            <SocialMedia />
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}

export default Navigation;
