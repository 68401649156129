import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import logo from '../assets/images/Logo-Subway.png';
import MainMenu from './MainMenu';
import SocialMedia from './SocialMedia';
import Logo from './Logo';
import AppButtons from './AppButtons';
import { Paragraph } from './text';


const ResponsiveMenu = styled(Nav)`
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.75rem;
  }
`;

function Footer() {
  return (
    <Container className="py-4 py-md-5 px-md-6 bg-dark" fluid tag="footer">
      <Container fluid>
        <Logo src={logo} alt="SUBWAY" />
      </Container>      
      
      <Row className="px-0 my-4" noGutters>
        <Col xs={6} md>
          <ResponsiveMenu vertical>
            <MainMenu className="font-weight-bold text-secondary py-1 py-md-2" />
          </ResponsiveMenu>
        </Col>

        <Col xs={6} md>
          <ResponsiveMenu vertical>
            <NavItem>
              <NavLink href="/contact" className="py-1 py-md-2">
                Contact
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contact" className="py-1 py-md-2">
                Customer Service
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about" className="py-1 py-md-2">
                About
              </NavLink>
            </NavItem>
          </ResponsiveMenu>
        </Col>

        <Col xs={12} md>
          <Paragraph sm={1} md={1.75} weight={700} className="mt-3 mt-md-0 text-secondary text-center text-md-left">
            Follow SUBWAY <span className="d-none d-md-inline-block">on social media</span>
          </Paragraph>
          <Nav className="justify-content-center justify-content-md-start">
            <SocialMedia />
          </Nav>
          <div className="text-center text-md-left">
            <Paragraph sm={1} md={1.75} weight={700} className="mt-3 text-secondary">
            Download the Subway App
            </Paragraph>
            <span className="mx-md-n3"><AppButtons /></span>
          </div>
        </Col>
      </Row>

      <Container fluid>
        <Paragraph sm={0.75} md={1} className="text-center text-md-left m-0">
          Subway is a registered trademark of Subway IP Inc. 2018 Subway IP inc. All rights reserved.
        </Paragraph>
      </Container>
    </Container>
  );
}

export default Footer;
