import React from 'react';

import AppleStore from '../assets/images/iphone-app-store.svg';
import GoogleStore from '../assets/images/google-app-store.svg';


function AppButtons({ height }) {
    return (
        <div>
            <a href="https://play.google.com/store/apps/details?id=uk.co.hungrrr.subwaytrinidad&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
                <img
                    alt="Get it on Google Play"
                    src={GoogleStore}
                    height={height}
                    className="app-btn"
                />
            </a>
            <a href="https://apps.apple.com/app/id1582160442#?platform=iphone" target="_blank">
                <img
                    alt="Download on the App Store"
                    src={AppleStore}
                    height={height}
                    className="app-btn"
                />
            </a>
        </div>
    );
}

AppButtons.defaultProps = {
    height: 50,
};

export default AppButtons;
