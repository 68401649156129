import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { NavItem, NavLink } from 'reactstrap';

import Facebook from '../assets/images/social-media/icon-Facebook.png';
import YouTube from '../assets/images/social-media/icon-YouTube.png';
import Instagram from '../assets/images/social-media/icon-Instagram.png';


function SocialMedia() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            name
            url
          }
        }
      }
    }
  `);

  const links = site.siteMetadata.social.reduce((result, item) => {
    result[item.name] = item.url;
    return result;
  }, {});

  return (
    <>
      <NavItem>
        <NavLink
          href={links.facebook}
          target="_blank"
          className="px-0"
        >
          <img src={Facebook} alt="Facebook" />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href={links.instagram}
          target="_blank"
          className="px-0"
        >
          <img src={Instagram} alt="Instagram" />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href={links.youtube}
          target="_blank" 
          className="px-0"
        >
          <img src={YouTube} alt="YouTube" />
        </NavLink>
      </NavItem>
    </>
  );
}

export default SocialMedia;
