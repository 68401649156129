import styled from 'styled-components';

const Heading1 = styled.h1`
  font-size: ${props => props.sm ? `${props.sm}rem` : 'inherit'};
  font-weight: ${props => props.weight || 700};

  @media (min-width: 768px) {
    font-size: ${props => props.md ? `${props.md}rem` : 'inherit'};
  }
`;

const Heading2 = styled.h2`
  font-size: ${props => props.sm ? `${props.sm}rem` : 'inherit'};
  font-weight: ${props => props.weight || 700};

  @media (min-width: 768px) {
    font-size: ${props => props.md ? `${props.md}rem` : 'inherit'};
  }
`;

const Heading3 = styled.h3`
  font-size: ${props => props.sm ? `${props.sm}rem` : 'inherit'};
  font-weight: ${props => props.weight || 300};

  @media (min-width: 768px) {
    font-size: ${props => props.md ? `${props.md}rem` : 'inherit'};
  }
`;

const Paragraph = styled.p`
  font-size: ${props => props.sm ? `${props.sm}rem` : 'inherit'};
  font-weight: ${props => props.weight || 300};

  @media (min-width: 768px) {
    font-size: ${props => props.md ? `${props.md}rem` : 'inherit'};
  }
`;

const Span = styled.span`
  font-size: ${props => props.sm ? `${props.sm}rem` : 'inherit'};
  font-weight: ${props => props.weight || 300};

  @media (min-width: 768px) {
    font-size: ${props => props.md ? `${props.md}rem` : 'inherit'};
  }
`;

export { Heading1, Heading2, Heading3, Paragraph, Span };
